import "../styles/Blogs.css"
import { HashLink as Link } from 'react-router-hash-link';

const Blogs = () => {
    return (
        <>
        <h2> Blog Posts </h2>
        <div className="blogViewContainer">
            <div className="blogView">
                <h3> <Link component={Link} to="/blogs/imgupscaler"> Bypassing Img.Upscaler Limit [06/23/2022] </Link></h3>
                <h4> How I reverse engineered this image upscaling webapp to bypass the weekly upload limit </h4>
            </div>
        </div>
        </>
    )
}

export default Blogs;