import { HashLink as Link } from 'react-router-hash-link';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';


const Navbar = () => {
    return (
        <div className='topNav'>
            <div className='menuContainer'>
                <Stack spacing={3} direction="row" style={{ justifyContent: 'center' }}>
                    <Button component={Link} to="../#about-me" variant="text" className='navButton'> ABOUT ME </Button>
                    <Button component={Link} to="../#skills" variant="text" className='navButton'> SKILLS </Button>
                    <Button component={Link} to="../#experience" variant="text" className='navButton'> EXPERIENCE </Button>
                    {/* <Button component={Link} to="/blogs" variant="text" className='navButton'> BLOG </Button> */}
                </Stack>
            </div>
        </div>
    );
}

export default Navbar;