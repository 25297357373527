
import './App.css';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter as Router, Routes , Route } from "react-router-dom";
import Blogs from "./components/Blogs"
import Home from './components/Home'
import Navbar from './components/Navbar';
import Imgupscaler from './components/blogs/imgupscaler/Imgupscaler';


const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#46AD8D"
    },
    background: {
      default: "#394764"
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <Router>
      <div className="App">
        <Navbar />
        <Routes >
          <Route path="/" exact element={<Home />} />
          {/* <Route path="/blogs" element={<Blogs />} /> */}
          {/* <Route path="/blogs/imgupscaler" element={<Imgupscaler />} /> */}
        </Routes >
      </div>
    </Router>
    </ThemeProvider>
  );
}

export default App;
